@import '../core.css';

.root {
  --container-padding-inline: 20px;
  --container-padding-block: 20px;
  padding: var(--container-padding-block) var(--container-padding-inline);
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
  overflow: hidden;
}

@media (--breakpoint-mobile) {
  .root {
    --container-padding-inline: 12px;
    --container-padding-block: 12px;
  }
}

.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

@media (--breakpoint-mobile) {
  .header {
    margin-bottom: 12px;
  }
}

.heading {
  font: var(--font-headline-h3);
}

.headerLink {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-primary-50);
  color: var(--color-primary-500);
  font: var(--font-body-m);
}

.headerLinkChevron {
  font-size: 12px;
}

.emptyContent {
  padding-top: 80px;
  padding-bottom: 125px;
}

@media (--breakpoint-mobile) {
  .emptyContent {
    padding-top: 50px;
    padding-bottom: 85px;
  }
}

.emptyContentHeading {
  font: var(--font-body-l-b);
  margin-bottom: 12px;
}

.emptyContentDescription {
  font: var(--font-body-m);
  color: var(--color-base-07);
}
