.select {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: start;
}

.select_variant_default,
.select_variant_defaultSmall {
  background: var(--color-base-01);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  font: var(--font-body-m);
}

.select_variant_default {
  padding: 12px 8px;
}

.select_variant_defaultSmall {
  padding: 8px;
}

.select_variant_minimal {
  background: none;
  border: 0;
  padding: 0;
  font: var(--font-body-m);
}

.select_autoWidth {
  width: auto;
}

.placeholder {
  color: var(--color-base-05);
}

.buttonIcon {
  flex-shrink: 0;
}

.floating {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  background: var(--color-base-00);
  box-shadow: 0px 16px 24px rgba(39, 39, 50, 0.12);
  border-radius: 12px;
  min-width: 100px;
  outline: 0;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 5px 15px;
  cursor: default;
  background: inherit;
  outline: 0;
  font: var(--font-body-m);
}

.option:first-child {
  margin-top: 12px;
}

.option:last-child {
  margin-bottom: 12px;
}

.optionActive {
  background: var(--color-base-01);
  color: var(--color-primary-500);
}

.optionSelectedIcon {
  color: var(--color-system-success);
  font: var(--font-headline-h2);
  flex-shrink: 0;
}
