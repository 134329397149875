.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  user-select: none;
  background: none;
  border: 0;
  padding: 0;
}

.btn.disabled {
  cursor: not-allowed;
  background-color: var(--color-base-04);
  border-color: var(--color-base-04);
  color: var(--color-base-00);
  box-shadow: unset;
  opacity: 1;
}

.btn_variant_default,
.btn_variant_icon,
.btn_variant_icon_danger,
.btn_variant_outlined,
.btn_variant_outlined_danger,
.btn_variant_danger {
  padding: 0.65em 1em;
  border-radius: 8px;
}

.btn_variant_default {
  background: var(--color-primary-500);
  color: var(--color-neutral-white);
  box-shadow: 0 2px 16px rgba(31, 90, 246, 0.4);
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
}

.btn_variant_default:not(.disabled):hover,
.btn_variant_default:not(.disabled):focus-visible {
  background-color: var(--color-primary-800);
  box-shadow: 0 1px 4px rgba(31, 90, 246, 0.4);
}

.btn_variant_icon {
  padding: 0.25em;
  color: inherit;
  transition: background-color 200ms ease-in-out;
}

.btn_variant_icon:not(.disabled):hover,
.btn_variant_icon:not(.disabled):focus-visible {
  background-color: rgba(220, 220, 220, 0.5);
}

.btn_variant_icon_danger {
  padding: 0.25em;
  transition: background-color 200ms ease-in-out;
  color: var(--color-system-error);
}

.btn_variant_icon_danger:not(.disabled):hover,
.btn_variant_icon_danger:not(.disabled):focus-visible {
  background-color: rgba(220, 220, 220, 0.5);
}

.btn_variant_outlined {
  border: 1px solid var(--color-primary-500);
  color: var(--color-primary-500);
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out,
    color 200ms ease-in-out;
}

.btn_variant_outlined:not(.disabled):hover,
.btn_variant_outlined:not(.disabled):focus-visible {
  background-color: rgba(31, 90, 246, 0.05);
}

.btn_variant_outlined_danger {
  border: 1px solid var(--color-system-error);
  color: var(--color-system-error);
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out,
    color 200ms ease-in-out;
}

.btn_variant_outlined_danger:not(.disabled):hover,
.btn_variant_outlined_danger:not(.disabled):focus-visible {
  background-color: rgba(248, 33, 58, 0.05);
}

.btn_variant_danger {
  color: var(--color-base-00);
  background: var(--color-system-error);
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

.btn_variant_danger:not(.disabled):hover,
.btn_variant_danger:not(.disabled):focus-visible {
  background-color: #c72a34;
}

.btn_loading {
  position: relative;
}

.btn_loading .children {
  color: transparent;
}

.spinnerWrapper {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.spinner.spinner {
  color: inherit;
}
