@import '../core.css';

.container {
  margin-inline: auto;
  max-width: var(--content-max-width);
}

.root {
  isolation: isolate;
  min-height: 100%;
  background: var(--color-base-01);
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-areas:
    'header header'
    'sidebar content'
    'footer footer';
}

@media (--breakpoint-mobile) {
  .root {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'content'
      'footer';
  }
}

.header {
  grid-area: header;
}

.main {
  --container-padding-top: 40px;
  --container-padding-bottom: 40px;
  --container-padding-inline: 40px;

  grid-area: content;
  padding: var(--container-padding-top) var(--container-padding-inline)
    var(--container-padding-bottom);
}

@media (--breakpoint-mobile) {
  .main {
    --container-padding-top: 12px;
    --container-padding-bottom: 32px;
    --container-padding-inline: 12px;
  }
}

.sidebar {
  grid-area: sidebar;
  padding-left: 28px;
  border-right: 1px solid var(--color-base-03);
}

@media (--breakpoint-mobile) {
  .sidebar {
    display: none;
  }
}

.footer {
  grid-area: footer;
}
