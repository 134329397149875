.mobileMenu {
  touch-action: pan-y;
  z-index: 1;
}

.mobileMenuSidebar {
  touch-action: pan-y;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 280px;
  width: 100%;
  background: var(--color-base-00);
  border-radius: 0 20px 20px 0;
  transform: translateX(-100%);
  padding: 12px 0 0 28px;
  overflow: auto;
  transition: transform 0.3s;
}

.mobileMenuBackdrop {
  position: fixed;
  inset: 0;
  background: var(--color-base-00);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
}

.mobileMenu_open .mobileMenuBackdrop {
  opacity: 0.7;
  visibility: visible;
}

.mobileMenu_open .mobileMenuSidebar {
  transform: translateX(0);
  box-shadow: 0 16px 24px rgba(39, 39, 50, 0.12);
}

.mobileMenuLogoContainer {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobileMenuLogo img {
  width: 90px;
}

.betaBadge {
  padding: 2px 6px;
  font: var(--font-body-m);
  color: var(--color-primary-500);
  background: var(--color-primary-50);
  border-radius: 4px;
}
