@import '../core.css';

.dialogOverlay {
  background: rgba(255, 255, 255, 0.8);
  display: grid;
  place-items: center;
}

.dialog {
  padding: 20px;
  margin: 20px;
  width: calc(100% - 40px);
  background: #ffffff;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  position: relative;
}

@media (--breakpoint-mobile) {
  .dialog {
    padding: 12px;
    margin: 12px;
    width: calc(100% - 24px);
  }
}

.dialogHeader {
  display: grid;
  gap: 12px;
  margin-bottom: 20px;
}

.dialogTitle {
  font: var(--font-headline-h3);
  color: var(--color-base-09);
  margin-right: 23px;
}

.dialogDescription {
  font: var(--font-body-m);
  color: var(--color-base-07);
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 15.5px;
}
