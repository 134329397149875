@import '../../core.css';

.heading {
  margin-block-end: 12px;
  font: var(--font-headline-h1);
  text-align: center;
}

@media (--breakpoint-mobile) {
  .heading {
    font: var(--font-headline-h2);
  }
}

.subheading {
  text-align: center;
  font: var(--font-body-l);
  color: var(--color-base-07);
}

@media (--breakpoint-mobile) {
  .subheading {
    margin-top: 20px;
  }
}

.walletList {
  max-width: 790px;
  margin-inline: auto;
  margin-top: 44px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px 20px;
}

@media (--breakpoint-mobile) {
  .walletList {
    margin-top: 24px;
    gap: 20px;
  }

  .walletListItem {
    width: 100%;
  }
}

.walletButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 360px;
  border-width: 0;
  border-color: #e2e7f7;
  border-style: solid;
  padding: 21px;
  background: linear-gradient(
      222.65deg,
      #e9efff -10.82%,
      rgba(233, 239, 255, 0) 54.09%
    ),
    #ffffff;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.walletButton:not(:disabled):hover {
  border-width: 1px;
  border-color: #b6c7f8;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(39, 39, 50, 0.08);
}
.walletButton:disabled {
  background: var(--color-base-02);
  box-shadow: none;
}

@media (--breakpoint-mobile) {
  .walletButton {
    width: 100%;
    padding: 13px;
    flex: 1;
  }

  .walletButton:not(:disabled):hover {
    padding: 12px;
  }
}

.walletButtonIllustration {
  position: absolute;
  user-select: none;
}

.walletButton:disabled .walletButtonIllustration {
  opacity: 0.7;
}

.blurredCircleContainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: 12px;
}
.walletButton:disabled .blurredCircleContainer {
  display: none;
}
.blurredCircle {
  position: absolute;
  width: 57px;
  height: 57px;
  right: 25px;
  top: 25px;
  background: #4f64c3;
  filter: blur(27px);
}

.keeperWalletMobileIllustration {
  width: 146px;
  top: -29px;
  right: 0;
}
.walletButton:not(:disabled):hover .keeperWalletMobileIllustration {
  top: -30px;
  right: -1px;
}
@media (--breakpoint-mobile) {
  .keeperWalletMobileIllustration {
    width: 131px;
    top: -13px;
    right: 0;
  }
  .walletButton:not(:disabled):hover .keeperWalletMobileIllustration {
    top: -14px;
    right: -1px;
  }
}

.keeperWalletExtensionIllustration {
  top: -24px;
  right: 13px;
}
.walletButton:not(:disabled):hover .keeperWalletExtensionIllustration {
  top: -25px;
  right: 12px;
}
@media (--breakpoint-mobile) {
  .keeperWalletExtensionIllustration {
    width: 95px;
    top: -9px;
    right: 9.7px;
  }
  .walletButton:not(:disabled):hover .keeperWalletExtensionIllustration {
    top: -10px;
    right: 8.7px;
  }
}

.walletButtonLogoContainer {
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background: var(--color-primary-50);
}
.walletButton:disabled .walletButtonLogoContainer {
  background: #eaeaf3;
}
.walletButtonLogo {
  width: 48px;
}
.walletButton:disabled .walletButtonLogo {
  opacity: 0.5;
}
@media (--breakpoint-mobile) {
  .walletButtonLogoContainer {
    margin-bottom: 12px;
  }
  .walletButtonLogo {
    width: 36px;
  }
}

.walletButtonLabel {
  position: relative;
  font: var(--font-headline-h2);
  margin-bottom: 12px;
}
.walletButton:disabled .walletButtonLabel {
  color: var(--color-base-07);
}
@media (--breakpoint-mobile) {
  .walletButtonLabel {
    font: var(--font-headline-h3);
    margin-bottom: 8px;
  }
}

.walletButtonDescription {
  position: relative;
  font: var(--font-body-l);
  color: var(--color-base-06);
}
.walletButton:disabled .walletButtonDescription {
  color: var(--color-base-06);
}

.debugAccountModal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.debugAccountModalContent {
  max-width: 300px;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.debugAccountModalContent form {
  display: flex;
  flex-direction: column;
}

.debugAccountModalContent label {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}

.getKeeperMessage {
  text-align: center;
  margin-top: 40px;
  margin-inline: 63px;
  color: var(--color-base-07);
}

@media (--breakpoint-mobile) {
  .getKeeperMessage {
    margin-top: 20px;
  }
}

.getKeeperMessage a:hover {
  text-decoration: underline;
}
