@import '../core.css';

.connectedAccountContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (--breakpoint-mobile) {
  .connectWalletButton {
    padding-block: 7px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  .connectedAccountContainer {
    margin-top: 32px;
    margin-bottom: 4px;
  }
}

.connectedAccount {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  background: var(--color-base-01);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  font: var(--font-body-m);
  color: var(--color-base-09);
}

.connectedAccountIcon {
  font-size: 24px;
  color: var(--color-base-06);
}

.logoutButton {
  color: transparent;
  transition: color 0.2s;
  border: none;
  padding: unset;
  background: transparent;
  display: block;
}

.logoutButton:hover {
  color: rgba(31, 90, 246, 0.05);
}
