.input {
  background: var(--color-base-01);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  padding: 12px 8px;
  font: var(--font-body-m);
  width: 100%;
}

.input::placeholder {
  color: var(--color-base-06);
}

.error {
  color: var(--color-system-error);
  border-color: var(--color-system-error);
}
