@import '../core.css';

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  font: var(--font-headline-h2);
  margin-bottom: 20px;
}

@media (--breakpoint-mobile) {
  .header {
    gap: 0;
    margin-bottom: 12px;
    font: var(--font-headline-h3);
  }
}

.backButton {
  margin-block: -2.5em;
}

.headingSkeleton {
  width: 148px;
  height: 28px;
}

.itemsCount {
  color: var(--color-base-07);
  font: var(--font-headline-h3);
}

@media (--breakpoint-mobile) {
  .itemsCount {
    font: var(--font-body-l-b);
  }
}

.cardList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 208px);
}

@media (--breakpoint-mobile) {
  .cardList {
    gap: 12px;
    grid-template-columns: repeat(auto-fill, 142px);
  }
}

.cardListItem {
  position: relative;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
  color: var(--color-base-09);
}

@media (--breakpoint-mobile) {
  .cardListItem {
    padding: 8px;
    border-radius: 8px;
  }
}

.cardImage {
  width: 184px;
  height: 184px;
  border-radius: 12px;
  object-fit: cover;
}

@media (--breakpoint-mobile) {
  .cardImage {
    width: 126px;
    height: 126px;
    border-radius: 4px;
  }
}

.cardCaption {
  margin-block-start: 12px;
  font: var(--font-body-l-b);
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .cardCaption {
    margin-block-start: 8px;
    font: var(--font-body-s-b);
  }
}

.cardSkeleton {
  height: 239px;
}

@media (--breakpoint-mobile) {
  .cardSkeleton {
    height: 164px;
  }
}
