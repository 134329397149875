@import '../core.css';

.card {
  display: grid;
  gap: 12px;
  padding: 20px;
  background: var(--color-base-00);
  box-shadow: 0px 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  max-width: 500px;
}

@media (--breakpoint-mobile) {
  .card {
    padding: 12px;
  }
}

.assetLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: -4px 0;
}

.senderOptionLabel {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
}

.senderBalanceInTokensLabel,
.senderBalanceInUsdLabel {
  display: flex;
  justify-content: flex-end;
}

.senderOptionAddress,
.senderBalanceInUsdLabel {
  color: var(--color-base-06);
}

.feeOptionLabel,
.assetOptionLabel {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
}

.assetIcon,
.assetLabel {
  grid-row: 1/3;
}

.amountInTokensLabel,
.amountInUsdLabel {
  display: flex;
  justify-content: flex-end;
}

.amountInUsdLabel {
  grid-column: 3;
  grid-row: 2;
  color: var(--color-base-06);
}

.assetInput,
.recipientInput {
  position: relative;
}

.assetSpinner,
.recipientSpinner {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin-block: auto;
}

.senderLabel,
.recipientLabel,
.assetHelperText,
.amountHelperText {
  display: flex;
  justify-content: space-between;
}
.senderLabel,
.recipientLabel {
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.amountUseMaxButton {
  color: var(--color-primary-500);
  background: none;
  border: none;
}

.walletBalanceLabel {
  display: flex;
  gap: 4px;
}

.feeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.feeButtonLabel {
  color: var(--color-base-06);
}

.formError {
  color: var(--color-system-error);
  font: var(--font-body-m);
}
