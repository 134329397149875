.assetCell {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  align-items: center;
}

.assetCellTicker {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assetCellLogo {
  font-size: 24px;
  object-fit: contain;
}
