.assetListHeader {
  display: flex;
  justify-content: space-between;
  margin-inline: calc(var(--container-padding-inline) * -1);
  padding-inline: var(--container-padding-inline);
  background-color: var(--color-base-01);
  padding-block: 12px;
  font: var(--font-body-s);
}

.assetListItem {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 0;
}

.assetListItemLogo {
  width: 24px;
}

.assetListItemContent {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
}

.assetListItemPriceChange {
  margin-left: 8px;
}

.assetListItemContentTop {
  display: flex;
  gap: 8px;
  font: var(--font-body-s-b);
}

.assetListItemContentBottom {
  display: flex;
  gap: 8px;
  font: var(--font-body-s);
}

.assetListItemContentTop > *:first-child,
.assetListItemContentBottom > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.assetListItemContentTop > *:last-child,
.assetListItemContentBottom > *:last-child {
  flex: 1;
  text-align: right;
}

.assetListItemWorth {
  color: var(--color-base-09);
}
