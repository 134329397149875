.copyButtonContent {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.copyIcon {
  font-size: 20px;
  color: var(--color-primary-500);
}
