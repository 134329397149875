@import '../core.css';

.header {
  isolation: isolate;
  z-index: 1;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--color-base-00);
  border-bottom: 1px solid var(--color-base-03);
}

.header,
.headerPlaceholder {
  height: 60px;
}

@media (--breakpoint-mobile) {
  .header,
  .headerPlaceholder {
    height: 48px;
  }
}

.headerContainer {
  padding-left: 28px;
  padding-right: 40px;
  flex-grow: 1;
}

.headerLayout {
  align-items: center;
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
}

@media (--breakpoint-mobile) {
  .headerContainer {
    padding-left: 8px;
    padding-right: 8px;
  }

  .headerLayout {
    grid-template-columns: 1fr auto 1fr;
  }
}

.headerContent {
  display: flex;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo {
  display: block;
}

.logo img {
  width: 112.5px;
}

.betaBadge {
  padding: 2px 6px;
  font: var(--font-body-m);
  color: var(--color-primary-500);
  background: var(--color-primary-50);
  border-radius: 4px;
}

@media (--breakpoint-mobile) {
  .logoContainer {
    justify-content: center;
  }

  .logo img {
    width: 90px;
  }
}

.rightControls {
  margin-inline-start: auto;
  display: flex;
  flex-direction: column;
}

@media (--breakpoint-mobile) {
  .rightControls {
    display: none;
  }
}

.accountSelectWrapper {
  width: 160px;
}

.mobileMenuTriggerContainer {
  display: none;
  font-size: 24px;
}

@media (--breakpoint-mobile) {
  .mobileMenuTriggerContainer {
    display: block;
  }
}
